<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3 class="text-primary fw-bold mb-4">App Builder</h3>
        <create-app v-if="!app"></create-app>
        <div v-if="app">
          <h5 class="fw-bold mb-4">{{ app.app_title }}</h5>
          <div class="row">
            <div class="col">
              <router-link to="/app-builder/general" class="btn btn-primary"
                >Manage App</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-auto ms-auto pt-5">
        <phone-simulator :src="pwaUrl"></phone-simulator>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/services/firebase";
import PhoneSimulator from "./partials/PhoneSimulator.vue";
const db = firebase.firestore();
export default {
  data() {
    return {
      app: null,
    };
  },
  computed: {
    pwaUrl() {
      // return 'https://swandoola-pwa.swandoola.com';
      // return "https://pwa-demo.swandoola.com";
      return "https://pwa.swandoola.jtg:8081"
    },
  },
  methods: {
    async readApp() {
      const doc = await db.collection("apps").doc("wXUPsAWUlid7azNBdZ33").get();
      const data = {
        id: doc.id,
        ...doc.data(),
      };
      this.app = data;
    },
  },
  async mounted() {
    await this.readApp();
    // console.log(this.app);
  },
  components: {
    PhoneSimulator,
  },
};
</script>

<style>
</style>